<script>
    export let prompts;
    export let toggleChatbot;
    export let submitMessage;
    export let showBot;
    export let lightness;
    export let scheme;
    export let chatbotId
</script>

<style>
    .promptButton {
        background-color: var(--prompt-bg-default);
        color: var(--prompt-text-default);
    }
    .promptButton:hover {
        background-color: var(--prompt-bg-hover);
        color: var(--prompt-text-hover);
    }
</style>

{#if prompts?.filter((prompt) => prompt.type === "SUGGESTED_MESSAGE").length >= 1}
<div
    class="flex gap-2 flex-wrap justify-end"
>
    {#each prompts.filter((prompt) => prompt.type === "SUGGESTED_MESSAGE") as prompt}
        <button
            class="promptButton font-medium rounded-lg text-sm px-5 py-2 text-left"
            type="button"
            on:click={() => {
                if (!showBot) {
                    toggleChatbot();
                }
                if(window.xprops?.gaID){
                    window.analytics.track('suggestedPromptClicks', {sessionId: localStorage.getItem("sessionId-" + chatbotId), userId: localStorage.getItem("userId-" + chatbotId), chatbotId: chatbotId, prompt_text: prompt?.title , cookie_flags: 'SameSite=None; Secure'});
                 }
                submitMessage(prompt.title);
            }}
            style:--prompt-bg-default={lightness(scheme.header, 90)}
            style:--prompt-bg-hover={scheme.header}
            style:--prompt-text-default={scheme.header}
            style:--prompt-text-hover={scheme.headerText}
            >{prompt.title}</button
        >
    {/each}
</div>
{/if}