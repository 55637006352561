<script>
    import { marked } from "marked";
    import { onMount } from 'svelte';
    import markedLinkifyIt from "marked-linkify-it";
    import { convertLinksToTargetBlank } from "./lib";
    import { useTooltip } from '@untemps/svelte-use-tooltip'
    import { onDestroy } from 'svelte';
    import SquarePayment from "./SquarePayment.svelte";


    const apiUrl = process.env.API_URL;

    marked.use({
        gfm: false,
        mangle: false,
        headerIds: false
    });
    marked.use(markedLinkifyIt({}, {}));
    export let chatbotId;
    export let chatId;
    export let scheme;
    export let message;
    export let date;
    export let botAvatar;
    export let loading = false;
    export let chatRating = null;
    export let welcomeMessage = false;
    export let isPayment;
    export let paymentData;
    export let queryType = determineQueryType(message);
    export let booking_id;
    export let booking_version;
    $: ratingStatus = chatRating;
    let messageContainer;

    let currentMessageIndex = 0;
    let cycleComplete = false;
    let activeMessages = [];

    const messages = {
        general: [
            [
                { id: 1, text: "Let me check that for you..." },
                { id: 2, text: "Thanks for waiting, I'm finding the information now..." }
            ],
            [
                { id: 1, text: "Good question, let me find out..." },
                { id: 2, text: "Just a moment, please..." }
            ],
            [
                { id: 1, text: "I'm looking into that right now..." },
                { id: 2, text: "Thanks for holding on, almost done..." }
            ],
            [
                { id: 1, text: "I'll get that information for you..." },
                { id: 2, text: "One second while I retrieve the details..." }
            ],
            [
                { id: 1, text: "Hang tight, checking on that..." },
                { id: 2, text: "Should have an answer for you soon..." }
            ],
            [
                { id: 1, text: "Let me see what I can find..." },
                { id: 2, text: "Almost there, getting that information for you..." }
            ]
        ],
        support: [
            { id: 1, text: "Checking support resources..." },
            { id: 2, text: "Finding relevant solutions..." }
        ],
        product: [
            { id: 1, text: "Looking up product details..." },
            { id: 2, text: "Checking specifications..." }
        ],
        booking: [
            { id: 1, text: "Checking availability..." },
            { id: 2, text: "Reviewing scheduling options..." }
        ],
        technical: [
            { id: 1, text: "Analyzing technical details..." },
            { id: 2, text: "Reviewing documentation..." }
        ]
    };

    function determineQueryType(message = '') {
        const text = message.toLowerCase();
        
        if (text.includes('help') || 
            text.includes('support') || 
            text.includes('issue') || 
            text.includes('problem')) {
            return 'support';
        }
        
        if (text.includes('product') || 
            text.includes('price') || 
            text.includes('cost') || 
            text.includes('features')) {
            return 'product';
        }
        
        if (text.includes('book') || 
            text.includes('schedule') || 
            text.includes('appointment') || 
            text.includes('reservation')) {
            return 'booking';
        }
        
        if (text.includes('how to') || 
            text.includes('setup') || 
            text.includes('install') || 
            text.includes('configure')) {
            return 'technical';
        }
        
        return 'general';
    }

    let lastUsedIndices = [];  // Track recently used message groups
    const maxTrackedMessages = 2;  // Reduced to ensure more variation

    function getRandomMessageGroup() {
        const messageGroups = messages.general;
        
        // Create array of indices we haven't used recently
        let availableIndices = Array.from(Array(messageGroups.length).keys())
            .filter(index => !lastUsedIndices.includes(index));

        // If we're running low on options, reset the tracking
        if (availableIndices.length <= 1) {
            lastUsedIndices = [];
            availableIndices = Array.from(Array(messageGroups.length).keys());
        }

        // Select random index from available options
        const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
        
        // Track this index
        lastUsedIndices.push(randomIndex);
        if (lastUsedIndices.length > maxTrackedMessages) {
            lastUsedIndices.shift();
        }

        return messageGroups[randomIndex];
    }

    function startMessageCycle() {
        cycleComplete = false;
        activeMessages = getRandomMessageGroup();
        
        setTimeout(() => {
            if (!cycleComplete) {
                currentMessageIndex = 1;
                setTimeout(() => {
                    cycleComplete = true;
                }, 400); // Reduced timeout for faster bot response
            }
        }, 2000);
    }

    $: if (loading && currentMessageIndex === 0) {
        startMessageCycle();
    }

    onDestroy(() => {
        cycleComplete = true;
    });

    async function toggleThumbs(chatId, rating) {
        await fetch(
            `${apiUrl}/api/public/chats/${chatId}/ratings`,
            {
                method: "POST",
                body: JSON.stringify({
                    userId: localStorage.getItem("userId-" + chatbotId),
                    sessionId: localStorage.getItem("sessionId-" + chatbotId),
                    chatId,
                    rating,
                }),
            }
        );
        ratingStatus = rating;
    }

    onMount(() => {
        if (messageContainer) {
            messageContainer.addEventListener('click', (event) => {
                const target = event.target.closest('a[data-track]');
                if (target) {

                    const link = target.href;

                    if (window.xprops?.gaID) {

                        window.analytics.track('link_clicked', {
                            sessionId: localStorage.getItem(`sessionId-${chatbotId}`),
                            userId: localStorage.getItem(`userId-${chatbotId}`),
                            chatbotId: chatbotId,
                            clickedLink: link,
                            cookie_flags: 'SameSite=None; Secure',
                        });
                    }
                }
            });
        }
    });

    let showAllTimeSlots = false;
    let currentPage = 1;
    
    function isTimeSlotMessage(message) {
        return message.includes("Please select a time slot:") && message.includes("<li>");
    }

    function parseTimeSlotMessage(message) {
        const prefix = message.split("<li>")[0];
        const slots = message.match(/<li>.*?<\/li>/g) || [];
        return { prefix, slots };
    }

    $: formattedMessage = isTimeSlotMessage(message) ? 
        parseTimeSlotMessage(message) : 
        { prefix: message, slots: [] };

    $: visibleSlots = formattedMessage.slots.slice(0, currentPage * 7);
    $: remainingSlots = formattedMessage.slots.length - visibleSlots.length;

    function handleShowMore() {
        currentPage += 1;
    }
</script>

<div class="flex items-center mb-[20px] max-w-full">
    <div
        class="flex-none self-start flex flex-col items-center space-y-1 mr-[8px]"
    >
        <img
            class="rounded-full w-[30px] h-[30px]"
            src={botAvatar}
            alt="Bot Avatar"
        />
    </div>
    <div>
        <div
            style="border-radius: 3px 10px 10px 10px; background-color: {scheme.aiBubble}; color: {scheme.aiBubbleText};"
            class="flex-1 min-w-[200px] p-[20px] rounded-lg mb-2 relative text-[14px] leading-[24px] font-normal"
        >
            {#if isPayment}
                <SquarePayment paymentData={paymentData} {chatbotId} booking_id={booking_id} booking_version={booking_version}/>
            {:else}
                <div class="prose prose-sm">
                    <div class="thinking-state" style="color: {scheme.aiBubbleText}">
                        {#if loading && !cycleComplete}
                            <span class="loading-message">{activeMessages[currentMessageIndex]?.text}</span>
                        {:else if loading && cycleComplete}
                            <div class="prose prose-sm loading-message">
                                {@html convertLinksToTargetBlank(marked.parse(activeMessages[1].text))}
                            </div>
                        {:else}
                            <div class="prose prose-sm">
                                {#if isTimeSlotMessage(message)}
                                    {@html convertLinksToTargetBlank(marked.parse(formattedMessage.prefix))}
                                    {#each visibleSlots as slot}
                                        {@html convertLinksToTargetBlank(marked.parse(slot))}
                                    {/each}
                                    {#if remainingSlots > 0}
                                        <div class="show-more-link">
                                            <button 
                                                on:click={handleShowMore}
                                                class="text-[#3182CE] hover:underline text-[14px]"
                                            >
                                                (Show next {Math.min(7, remainingSlots)} time slots)
                                            </button>
                                        </div>
                                    {/if}
                                {:else}
                                    {@html convertLinksToTargetBlank(marked.parse(message))}
                                {/if}
                            </div>
                        {/if}
                    </div>
                </div>
            {/if}
        </div>
        <div
            class="text-[#718096] text-[12px] leading-[16px] font-normal flex flex-wrap justify-between"
        >
            <div>
                {#if date}{date}{/if}
            </div>
            {#if !welcomeMessage}
            <div class="flex">
                {#if ratingStatus != false}
                <button 
                    class={ratingStatus === false ? 'fade-out' : ''}
                    on:click={() => toggleThumbs(chatId, true)}       
                    use:useTooltip={{
                        content: 'Good Response',
                        position: 'left',
                        containerClassName: `tooltip tooltip-right`,
                        animated: true,
                        animationEnterClassName: 'tooltip-enter',
                        animationLeaveClassName: null,
                        enterDelay: 100,
                        leaveDelay: 100,
                        offset: 10
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class={`inline-block w-3 h-3 hover:brightness-0 ` + ((ratingStatus === true) ? "brightness-0" : "")}
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                        />
                    </svg>
                </button>
                {/if}

                {#if ratingStatus != true}
                <button 
                    class="ml-[5px] {ratingStatus === true ? 'fade-out' : ''}"
                    on:click={() => toggleThumbs(chatId, false)} 
                    use:useTooltip={{
                        content: 'Bad Response',
                        position: 'left',
                        containerClassName: `tooltip tooltip-right`,
                        animated: true,
                        animationEnterClassName: 'tooltip-enter',
                        animationLeaveClassName: null,
                        enterDelay: 100,
                        leaveDelay: 100,
                        offset: 10
                    }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class={`inline-block w-3 h-3 hover:brightness-0 ` + ((ratingStatus === false) ? "brightness-0" : "")}
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384"
                        />
                    </svg>
                </button>
                {/if}
            </div>
            {/if}
        </div>
    </div>
</div>

<style>

    .thinking-state {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        opacity: 0.8;
    }

    .loading-message {
        animation: fadeInOut 2s ease-in-out infinite;
        min-width: 200px;
    }

    @keyframes fadeInOut {
        0% { opacity: 0.4; }
        50% { opacity: 1; }
        100% { opacity: 0.4; }
    }

    :global(.tooltip) {
        position: absolute;
        z-index: 9999;
        min-width: 80;
        max-width: 120px;
        max-height: 30px;
        font-size: smaller;
        background-color: #000001;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 0.5rem;
    }

    :global(.tooltip::after) {
        content: '';
        position: absolute;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
    }

    :global(.tooltip-right::after) {
        top: calc(50% - 5px);
        right: 0px;
        border-color: transparent transparent transparent #000001;
        display: none;
    }

    :global(.tooltip-enter) {
        animation: fadeIn 0.2s linear forwards;
    }

    .fade-out {
        opacity: 0;
        transition: opacity 0.3s ease; /* Adjust the duration and easing as needed */
    }

    :global(.show-more-link) {
        margin-top: 8px;
    }

    :global(.show-more-link button) {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }
</style>